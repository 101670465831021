<template>
  <div id="customer-summary">
    <c-nav-bar
      title="客户账本统计"
      @handleFilter="handleFilter"
      :right="'filter'"
      name="customer-summary"
    >
    </c-nav-bar>
    <div class="content">
      <div class="panel">
        <div class="title">添加：{{ data.add_total | formatMoneyValue }}</div>
        <div class="item">自助加账：{{ data.add | formatMoneyValue }}</div>
        <div class="item">
          手工添加：{{ data.manual_add | formatMoneyValue }}
        </div>
        <div class="item">奖金加账：{{ data.exchange | formatMoneyValue }}</div>
        <div class="item">
          撤单退回：{{ data.refund_project | formatMoneyValue }}
        </div>
        <div class="item">
          清账驳回：{{ data.refund_settlement | formatMoneyValue }}
        </div>
      </div>
      <div class="panel van-hairline--top">
        <div class="title">扣减：{{ data.sub_total | formatMoneyValue }}</div>
        <div class="item">自助清账：{{ data.sub | formatMoneyValue }}</div>
        <div class="item">
          手工扣减：{{ data.manual_sub | formatMoneyValue }}
        </div>
        <div class="item">投注扣减：{{ data.bet | formatMoneyValue }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

import CNavBar from "@/components/CNavBar.vue";

import customer from "@/api/customer";

export default {
  name: "customer-summary",
  data() {
    return {
      data: {},
      start: "2021-01-01",
      end: dayjs().format("YYYY-MM-DD"),
    };
  },
  created() {
    this.handleGetSummary();
  },
  components: {
    CNavBar,
  },
  methods: {
    handleFilter(val) {
      this.start = val.start_date;
      this.end = val.end_date;
      this.handleGetSummary();
    },
    handleGetSummary() {
      customer
        .getSummary(this.$route.query.pk, {
          start: this.start,
          end: this.end,
        })
        .then((res) => {
          this.data = res.results;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="stylus" scoped>
#customer-summary {
  height: 100%;

  .content {
    position: fixed;
    left: 0;
    right: 0;
    top: 56px;
    background: #ffffff;
    padding: 0 20px;

    .panel {
      padding: 20px 0;
    }

    .title {
      font-size: 18px;
    }

    .item {
      font-size: 14px;
      color: #666666;
      padding: 2px 0;
    }
  }
}
</style>
