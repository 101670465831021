<template>
  <div id="c-nav-bar">
    <van-nav-bar fixed>
      <template #left>
        <van-icon
          v-if="back"
          name="arrow-left"
          color="#000000"
          size="18"
          @click="handleGoBack"
        />
      </template>
      <template #title>
        <span class="title">{{ title }}</span>
      </template>
      <template v-if="right === 'filter' || right === 'zbtj'" #right>
        <div>
          <span @click="visible = true" v-if="right === 'filter'">
            {{ route.name === "customer-summary" ? "时间筛选" : "筛选" }}
          </span>
          <router-link
            v-if="right === 'zbtj'"
            tag="span"
            :to="{
              name: 'store-cooperation-history-statistic',
              query: { to_store_id: store.information.store_id },
            }"
            >总表统计</router-link
          >
        </div>
      </template>
    </van-nav-bar>

    <van-dialog v-model="visible" @confirm="handleConfirm" show-cancel-button>
      <div class="dialog-content">
        <div class="project-list">
          <p class="tal mb10 fs12" v-if="name === 'storePoint'">类型选择</p>
          <ul v-if="name === 'storePoint'">
            <li
              v-for="(item, key) in typeArray"
              :key="key"
              :class="{ activeType: activeType === key }"
              @click="handleSelectProjectType(key)"
            >
              {{ item }}
            </li>
          </ul>
          <p
            class="tal mb10 fs12"
            v-if="name === 'storePoint' || name === 'customer-summary'"
          >
            时间选择
          </p>
          <ul
            style="align-items: center"
            v-if="name === 'storePoint' || name === 'customer-summary'"
            @click="calendarShow = true"
          >
            <li class="wid-45">
              {{ start_date ? start_date : "起始时间" }}
            </li>
            <div>
              <span style="position: relative; top: -5px">:</span>
            </div>
            <li class="wid-45">
              {{ end_date ? end_date : "结束时间" }}
            </li>
          </ul>
          <p
            class="tal mb10 fs12"
            v-if="name === 'sendStore' || name === 'receiveStore'"
          >
            类型选择
          </p>
          <ul v-if="name === 'sendStore' || name === 'receiveStore'">
            <li
              v-for="(item, key) in typeFilter"
              :key="key"
              :class="{ storeType: storeType === key }"
              @click="handleSelectStoreType(key)"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </van-dialog>

    <van-calendar
      :min-date="minDate"
      v-model="calendarShow"
      @confirm="handleCalendarConfirm"
      type="range"
    ></van-calendar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "c-nav-bar",
  data() {
    return {
      calendarShow: false,
      minDate: new Date(2021, 0, 1),
      visible: false,
      typeArray: {
        1: "充值加点",
        // 2: "服务点提现",
        3: "接单扣点",
        // 4: "平台加点",
        5: "跟单扣佣",
        6: "跟单返佣",
        7: "发单佣金",
        // 8: "平台扣点"
      },
      typeFilter: {
        1: "收款明细",
        2: "清账",
        3: "投注",
        4: "兑奖金额",
        5: "手工添加",
        6: "手工扣减",
        7: "撤单退款",
        8: "清账撤回",
        9: "错奖回撤",
        10: "纠正返奖",
        11: "店铺返点",
        12: "跟单佣金",
        13: "跟单店主佣金",
        14: "发单佣金",
        15: "运营添加",
        16: "运营扣减",
      },
      activeType: "",
      start_date: "",
      end_date: "",
      storeType: "",
      route: "",
    };
  },
  created() {
    this.route = this.$route;
  },
  computed: {
    ...mapState({
      store: (state) => state.store,
    }),
  },
  props: {
    right: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleCalendarConfirm(data) {
      // 客户账本统计页面不能超过31天
      if (
        this.$route.name === "customer-summary" &&
        dayjs(data[1]).subtract(31, "day").isAfter(dayjs(data[0]))
      ) {
        this.$toast("时间跨度最多31天，请重新选择");
        return false;
      }
      this.calendarShow = false;
      this.start_date = dayjs(data[0]).format("YYYY-MM-DD");
      this.end_date = dayjs(data[1]).format("YYYY-MM-DD");
    },
    handleVisible() {
      this.visible = true;
    },
    handleSelectProjectType(val) {
      if (this.activeType === val) {
        this.activeType = "";
      } else {
        this.activeType = val;
      }
    },
    handleSelectStoreType(val) {
      if (this.storeType === val) {
        this.storeType = "";
      } else {
        this.storeType = val;
      }
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    handleConfirm() {
      this.$emit("handleFilter", {
        type: this.activeType,
        start_date: this.start_date,
        end_date: this.end_date,
        storeType: this.storeType,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
#c-nav-bar {
  z-index: 999;

  .title {
    color: #000000;
  }

  .dialog-content{
    padding: 10px;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 12px;

      li {
        width: 22%;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 5px 0;
        margin-bottom: 10px;
        text-align: center;
      }

      li.activeType, li.activeTime,li.storeType {
        border: 1px solid #FE3939;
        background: #FE3939;
        color: #ffffff;
      }

      .opacity {
        opacity: 0;
        z-index: -1;
      }
    }
  }
}
</style>
