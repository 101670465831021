import api from "@/api";

const customer = {
  get(pk) {
    return api.get(`/customer/${pk}`);
  },
  getSummary(pk, params) {
    return api.get(`/customer/${pk}/summary`, {
      params: params,
    });
  },
  listRecord(pk, params) {
    return api.get(`/customer/${pk}/record`, {
      params: params,
    });
  },
};

export default customer;
